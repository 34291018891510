import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 504.000000 512.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M1819 3935 c-307 -50 -537 -171 -748 -391 -153 -160 -239 -303 -306
-507 -52 -155 -67 -262 -68 -467 0 -194 13 -289 64 -461 43 -146 146 -343 234
-448 142 -171 271 -281 425 -364 224 -119 519 -166 760 -121 47 9 97 18 113
21 15 3 30 9 33 14 3 5 12 9 20 9 28 0 179 80 244 129 75 57 182 166 229 235
45 65 94 175 122 271 19 68 23 103 23 235 0 131 -4 167 -23 235 -39 141 -158
356 -211 385 -29 15 -722 14 -738 -2 -17 -17 -17 -569 0 -586 8 -8 52 -12 130
-12 113 0 120 -1 143 -25 59 -58 11 -138 -92 -151 -150 -18 -303 18 -431 103
-108 72 -172 162 -224 319 -19 59 -22 89 -22 204 1 120 4 143 27 209 33 92 96
190 149 234 22 17 46 38 53 45 8 6 16 12 20 12 3 0 23 11 43 25 55 38 182 75
281 82 91 6 188 -6 274 -34 31 -10 64 -13 95 -9 63 8 910 12 918 4 3 -4 7
-381 9 -840 2 -573 6 -844 14 -867 24 -75 88 -145 156 -171 17 -7 107 -14 200
-17 158 -4 173 -3 218 17 65 29 113 73 145 134 l27 51 3 840 c1 462 6 843 10
847 4 4 132 8 284 8 202 1 280 4 293 13 15 12 16 35 13 222 l-3 210 -34 62
c-42 79 -98 143 -147 168 -22 11 -41 23 -44 26 -3 4 -27 15 -55 25 -47 18
-105 19 -1040 24 -780 4 -997 8 -1025 18 -121 45 -400 64 -561 37z"
          />
          <path
            d="M1079 3640 c-76 -67 -189 -185 -195 -202 -3 -10 -9 -18 -14 -18 -13
0 -113 -168 -154 -258 -136 -301 -162 -733 -66 -1077 84 -301 314 -617 570
-782 225 -145 476 -210 743 -193 118 8 155 20 61 20 -90 0 -270 19 -349 37
-165 37 -350 124 -485 227 -76 58 -191 171 -240 236 -19 25 -37 47 -41 50 -19
15 -127 205 -156 274 -43 100 -87 265 -103 383 -18 133 -6 428 23 538 3 11 12
50 21 87 47 204 183 448 339 610 95 98 117 130 46 68z"
          />
          <path
            d="M2090 3137 l-45 -10 89 -18 c49 -10 96 -21 105 -26 9 -4 236 -10 506
-13 l489 -5 13 -25 c10 -20 13 -204 13 -840 0 -798 1 -816 21 -860 25 -55 81
-115 134 -143 35 -18 61 -21 213 -25 175 -4 262 2 262 19 0 5 -66 9 -157 9
-213 0 -284 20 -362 100 -22 23 -47 64 -60 99 -22 59 -22 64 -16 573 4 282 10
557 16 612 5 54 9 185 9 291 0 173 -2 194 -18 208 -17 15 -65 17 -489 17 -430
0 -474 2 -524 18 -71 25 -143 31 -199 19z"
          />
          <path
            d="M4197 3094 c-21 -21 14 -24 231 -22 152 2 236 7 235 13 -3 11 -455
20 -466 9z"
          />
          <path
            d="M1886 2674 c-3 -9 -6 -140 -6 -293 0 -340 -7 -321 123 -322 116 0
158 -14 171 -54 14 -41 13 -41 36 -20 23 21 26 53 6 81 -13 17 -28 19 -128 20
-92 0 -119 4 -141 19 l-28 18 7 257 c4 141 4 269 0 283 -7 30 -30 36 -40 11z"
          />
          <path
            d="M2647 1023 c-4 -3 -7 -16 -7 -29 0 -22 -170 -395 -192 -422 -15 -17
8 -34 37 -27 11 2 30 5 41 5 17 0 22 8 27 41 10 72 24 80 150 77 106 -3 111
-4 130 -30 11 -14 20 -40 21 -57 1 -21 7 -33 19 -37 26 -8 107 5 107 17 0 5
-47 105 -104 222 -58 116 -107 220 -110 230 -4 13 -17 17 -59 17 -30 0 -57 -3
-60 -7z m105 -207 c19 -43 26 -71 19 -75 -18 -11 -135 -4 -139 9 -2 6 8 41 23
76 37 87 57 85 97 -10z"
          />
          <path
            d="M2973 1013 c-3 -6 -3 -20 -1 -30 3 -15 17 -19 93 -23 l90 -5 5 -200
c4 -151 8 -200 18 -199 6 1 24 2 38 3 l25 1 -2 188 c-1 103 1 193 5 200 5 8
37 12 92 12 66 0 84 3 84 14 0 8 3 21 6 30 5 13 -21 16 -221 18 -169 2 -229 0
-232 -9z"
          />
          <path
            d="M3629 1028 c-1 -2 -3 -15 -4 -29 -4 -26 -39 -105 -145 -324 -32 -66
-56 -123 -54 -127 7 -11 62 -10 83 2 11 5 21 26 25 49 10 66 22 72 149 69
l111 -3 23 -34 c13 -19 23 -47 23 -62 0 -23 4 -29 23 -29 42 0 97 13 97 22 0
5 -45 98 -99 208 -55 110 -101 212 -103 227 -3 27 -5 28 -65 31 -35 2 -64 2
-64 0z m75 -149 c8 -12 25 -46 37 -76 29 -73 27 -74 -123 -64 -15 1 -8 32 21
96 17 36 34 65 40 65 5 0 17 -10 25 -21z"
          />
          <path
            d="M1910 785 l0 -234 157 3 c173 4 211 13 257 62 86 94 93 263 14 347
-45 46 -95 57 -268 57 l-159 0 -1 -235z m311 164 c59 -18 88 -61 95 -140 5
-54 2 -71 -18 -109 -35 -67 -66 -80 -188 -81 -56 -1 -104 2 -107 5 -19 19 -31
234 -17 303 l6 30 94 2 c51 1 112 -4 135 -10z"
          />
          <path
            d="M2058 919 c-16 -10 -7 -19 27 -25 39 -7 66 -23 92 -53 27 -30 39
-100 24 -129 -14 -26 -14 -42 -1 -42 5 0 12 11 16 25 3 14 10 25 15 25 5 0 9
28 9 63 0 72 -24 121 -66 138 -30 11 -97 9 -116 -2z"
          />
          <path
            d="M2537 1003 c-4 -3 -7 -16 -7 -28 0 -24 -30 -117 -40 -125 -6 -5 -40
-85 -40 -95 0 -15 22 -2 29 18 5 12 30 65 55 117 26 52 44 100 42 107 -6 14
-28 17 -39 6z"
          />
          <path
            d="M2868 973 c-7 -54 6 -83 36 -83 33 0 42 16 16 30 -11 6 -20 21 -20
35 0 60 -25 74 -32 18z"
          />
          <path
            d="M3522 994 c-5 -11 -12 -33 -15 -50 -3 -17 -33 -87 -66 -156 -34 -69
-61 -129 -61 -133 0 -4 -9 -20 -21 -37 -12 -16 -19 -32 -15 -35 12 -12 36 20
58 78 13 32 26 59 30 59 5 0 8 7 8 16 0 14 57 132 70 144 3 3 17 26 30 52 23
44 24 49 8 64 -15 15 -17 15 -26 -2z"
          />
          <path
            d="M1798 993 c-2 -4 -1 -87 2 -183 3 -96 2 -202 -3 -234 -4 -38 -3 -64
3 -70 12 -12 294 -21 346 -12 74 14 17 26 -126 26 -176 0 -202 7 -192 54 4 17
7 119 7 226 0 173 -2 195 -17 198 -9 2 -18 -1 -20 -5z"
          />
          <path
            d="M3020 920 c-13 -9 -11 -13 10 -30 l25 -19 0 -185 c0 -207 -8 -188 75
-188 72 0 76 3 21 18 -30 8 -59 18 -65 23 -8 6 -9 66 -6 188 4 102 2 183 -3
191 -11 14 -36 15 -57 2z"
          />
          <path
            d="M3317 923 c-30 -8 -13 -28 26 -31 40 -3 49 11 18 28 -11 5 -21 9 -23
9 -2 -1 -11 -3 -21 -6z"
          />
          <path
            d="M2678 642 c-58 -4 -63 -17 -10 -26 47 -7 68 -29 77 -78 5 -25 13 -38
28 -42 32 -8 119 -6 142 4 24 10 23 11 -38 16 -61 5 -87 19 -87 45 0 30 -27
76 -47 80 -10 2 -39 3 -65 1z"
          />
          <path
            d="M3658 643 c-57 -5 -63 -18 -12 -26 46 -8 74 -33 74 -67 0 -44 28 -60
101 -59 35 0 73 4 83 8 18 7 18 8 -5 14 -13 4 -40 7 -60 7 -47 0 -71 20 -74
60 -3 31 -36 73 -54 68 -3 -1 -27 -3 -53 -5z"
          />
          <path
            d="M3326 543 c-3 -4 -6 -15 -6 -25 0 -19 5 -20 85 -20 68 1 67 1 60 12
-3 6 -22 10 -41 10 -19 0 -48 7 -63 15 -16 8 -31 11 -35 8z"
          />
          <path
            d="M2335 529 c-14 -22 24 -38 88 -36 71 2 79 19 15 29 -24 4 -55 10 -69
13 -17 4 -29 2 -34 -6z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
